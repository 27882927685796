import React from 'react'
import Container from '../components/Container'
import Login from '../components/Login'
import Profile from '../components/Profile'
import PrivateRoute from '../components/PrivateRoute'
// import ObjectViewer from '../templates/objectviewer'
import { Router } from '@reach/router'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'

const App = ({ data, previousLocation, location }) => {
  // useEffect(() => {
  //   console.log('app mounted')
  // })

  const App = styled.div``

  const privatePosts = data.allContentfulPrivatePost.edges

  // console.log(privatePosts)

  return (
    <App>
      <Container>
        <Router>
          <Login
            path="/app/"
            data={privatePosts}
            previousLocation={previousLocation}
            location={location}
          />
          <PrivateRoute
            path="/app/profile/"
            component={Profile}
            data={privatePosts}
          />
          {/* <PrivateRoute
            path="/app/objectviewer/"
            component={ObjectViewer}
            data={null}
          /> */}
        </Router>
      </Container>
    </App>
  )
}

export default App

// this version there is no projectModelFile
export const appQuery = graphql`
  query appQuery {
    allContentfulPrivatePost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          metric1Result
          metric1Title
          metric2Result
          metric2Title
          metric3Result
          metric3Title
          node_locale
          password
          publishDate(formatString: "MMMM DD, YYYY")
          publishDateISO: publishDate(formatString: "YYYY-MM-DD")
          quote {
            quote
            childMarkdownRemark {
              html
            }
          }
          quotePerson
          quoteTitle
          title
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp
            }
            ogimg: resize(width: 1800) {
              src
              width
              height
            }
          }
          heroVideo {
            file {
              url
            }
            description
            title
          }
          id
          download {
            file {
              url
            }
          }
          abstract {
            abstract
            childMarkdownRemark {
              html
            }
          }
          bodyTitle
          body {
            body
            childMarkdownRemark {
              html
            }
          }
          images {
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          paragraph {
            paragraph
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 320)
            }
          }
        }
      }
    }
  }
`

// this version there is projectModelFile
// export const appQuery = graphql`
//   query appQuery {
//     allContentfulPrivatePost(sort: { order: DESC, fields: [publishDate] }) {
//       edges {
//         node {
//           password
//           title
//           id
//           publishDate(formatString: "MM/DD/YYYY")
//           projectModelFile {
//             id
//             title
//             slug
//             coverProject {
//               fluid(maxWidth: 320) {
//                 ...GatsbyContentfulFluid_withWebp_noBase64
//               }
//               title
//             }
//             projectFile {
//               title
//               file {
//                 url
//                 fileName
//               }
//             }
//             multiplicationFactor
//             unitOfMeasurement
//             altitude
//             azimuth
//             publishDate(formatString: "MM/DD/YYYY")
//           }
//         }
//       }
//     }
//   }
// `
