import React, { useEffect } from 'react'
import { getUser, isLoggedIn, logout } from '../services/auth'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import ImageGallery from './ImageGallery'
import Quote from './Quote'
import Results from './Results'
import Download from './Download'
import PageBody from './PageBody'
import ScrollingText from './ScrollingText'
import TextEffect from '../components/riseEffect/TextEffect'
import Media from '../components/Media'

const Profile = ({ data }) => {
  let secretPost = {}

  data.forEach(post => {
    if (post.node.password === getUser().username) {
      secretPost = post.node
    }
  })

  const {
    title,
    metaDescription,
    heroImage,
    heroVideo,
    body,
    publishDate,
    bodyTitle,
    abstract,
    paragraph,
    quote,
    quotePerson,
    quoteTitle,
    metric1Result,
    metric1Title,
    metric2Result,
    metric2Title,
    metric3Result,
    metric3Title,
    images,
    download,
  } = secretPost

  const HeroImageContainer = styled.div`
    padding-top: 40px;
    border-top: 1px solid white;

    .gatsby-image-wrapper {
      div:first-of-type {
        padding-bottom: 63% !important;
      }
    }

    @media (max-width: 700px) {
      .gatsby-image-wrapper {
        div:first-of-type {
          padding-bottom: 100% !important;
        }
      }
    }
  `

  const TitleContainer = styled.section`
    margin: 40px 0;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  `

  const TextContainer = styled.section`
    padding: 40px 0 80px 0;
    display: flex;
    justify-content: space-between;

    .leftText {
      display: flex;
    }

    .abstract {
      width: 350px;
      font-size: 1.6rem;

      p {
        line-height: 1.3;
      }
    }

    .paragraph {
      width: 375px;
      margin-left: 100px;
      font-weight: 300;

      p {
        line-height: 1.6;
      }
    }

    .date {
      p {
        font-size: 0.8rem;
        font-weight: 700;
      }
    }

    @media (max-width: 1050px) {
      .leftText {
        width: 100%;
        flex-direction: column;
      }

      .abstract {
        width: 570px;
        margin-bottom: 30px;
      }

      .paragraph {
        width: 570px;
        margin-left: 0;
      }
    }

    @media (max-width: 840px) {
      .abstract {
        width: 85%;
      }

      .paragraph {
        width: 85%;
      }
    }

    @media (max-width: 700px) {
      flex-direction: column-reverse;

      .date {
        margin-bottom: 15px;

        span {
          display: inline-block;
        }
      }
    }

    @media (max-width: 550px) {
      .abstract {
        width: 100%;
      }

      .paragraph {
        width: 100%;
      }
    }
  `

  return (
    <>
      {heroImage && (
        <HeroImageContainer>
          {heroVideo ? (
            <Media
              videoCheck={heroVideo.file.url.slice(-3)}
              videoSrcURL={heroVideo.file.url}
              fluid={heroVideo.fluid}
              alt={heroVideo.title}
              title={heroVideo.title}
              description={heroVideo.description}
            />
          ) : (
            <Img fluid={heroImage.fluid} />
          )}
        </HeroImageContainer>
      )}

      <TitleContainer>
        <ScrollingText text={title} />
      </TitleContainer>

      <TextContainer>
        <div className="leftText">
          <div className="abstract">
            {abstract && (
              <TextEffect lineHeight={1.3} text={abstract.abstract} />
            )}
          </div>

          <div className="paragraph">
            {paragraph && (
              <TextEffect lineHeight={1.6} text={paragraph.paragraph} />
            )}
          </div>
        </div>

        <div className="date">
          <p>
            {publishDate.substr(0, 3).toUpperCase()}&nbsp;
            <span>{publishDate.substr(publishDate.length - 4)}</span>
          </p>
        </div>
      </TextContainer>

      {images && images.length > 0 && <ImageGallery images={images} />}

      <Quote quote={quote} quotePerson={quotePerson} quoteTitle={quoteTitle} />

      <Results
        metric1Result={metric1Result}
        metric1Title={metric1Title}
        metric2Result={metric2Result}
        metric2Title={metric2Title}
        metric3Result={metric3Result}
        metric3Title={metric3Title}
      />

      <PageBody body={body} bodyTitle={bodyTitle} />
      <Download download={download} />
    </>
  )
}

export default Profile
