import React from 'react'
import { navigate } from 'gatsby'
import { handleLogin, isLoggedIn } from '../services/auth'
import styled from '@emotion/styled'
import arrow from '../assets/images/arrow8.png'

import gsap from 'gsap'

const Form = styled.form`
  width: 75%;
  display: flex;
  justify-content: space-between;

  input,
  textarea {
    font-family: inherit;
    font-size: 2.2rem;
    background: #545454;
    color: white;
    font-weight: 100;

    &::-webkit-input-placeholder {
      color: white;
    }
    &::-moz-placeholder {
      color: white;
    }
    &:-ms-input-placeholder {
      color: white;
    }
    &:required {
      box-shadow: none;
    }
  }

  @media (max-width: 1050px) {
    input,
    textarea {
      font-size: 1.6rem;
    }
  }

  @media (max-width: 840px) {
    width: 100%;
  }

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

const LoginFormContainer = styled.div`
  h1 {
    font-family: 'NeueHaasGrotesk', serif !important;
    color: white;
    font-size: 2.8rem;
    margin: 35px 0 35px 0;
  }

  min-height: 78.2vh;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  border-top: 1px solid white;

  .form {
    position: relative;
  }

  .rejection {
    display: none;
    position: absolute;
    bottom: -55px;
    left: 0;
  }
`

const Name = styled.input`
  width: calc(100% - 205px);
  background-color: white;
  padding: 0 35px;
  padding-top: 5px;

  @media (max-width: 1050px) {
    width: calc(100% - 160px);

    padding: 4px 20px 0 20px;
  }

  @media (max-width: 550px) {
    width: 100%;
    padding: 20px;
  }
`

const Submit = styled.button`
  background: white;
  padding: 35px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: 0.2s;
  width: 170px;

  p {
    font-size: 1.4rem;
    margin-right: 7px;
    color: black !important;
    font-family: Helvetica, serif;
    transform: translateY(4px);
  }

  .arrowContainer {
    width: 23px;
    transform: rotate(-45deg) translateY(3.5px);
  }

  &:hover {
    background: #f02937 !important;
  }

  @media (max-width: 1050px) {
    padding: 25px 0;
    width: 135px;

    p {
      font-size: 1.3rem;
    }

    .arrowContainer {
      width: 21px;
    }
  }

  @media (max-width: 550px) {
    padding: 20px 0;
    margin-top: 35px;

    p {
      font-size: 1.2rem;
    }

    .arrowContainer {
      width: 19px;
    }
  }
`

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
    }
  }

  componentDidMount() {
    console.log('login mounted')
  }

  componentWillUnmount() {}

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.props.previousLocation.current = this.props.location.pathname
    handleLogin(this.state, this.props.data)
  }

  render() {
    if (isLoggedIn()) {
      const swiper = document.getElementById('swiperMain')

      var tl = gsap.timeline({
        onComplete: () => {
          navigate(`/app/profile/`)
          // navigate(`/app/objectviewer/`)
        },
      })

      tl.to(swiper, {
        transform: `translateY(0vh)`,
        display: 'flex',
        duration: 0,
      }).to(swiper, {
        delay: '0.2',
        transform: 'translateY(-100vh)',
        duration: 0.5,
        display: 'flex',
      })
    }

    return (
      <LoginFormContainer>
        <h1>Enter code</h1>
        <Form
          className="form"
          method="post"
          onSubmit={event => {
            this.handleSubmit(event)

            if (isLoggedIn()) {
              const swiper = document.getElementById('swiperMain')

              // var tl = gsap.timeline({
              //   onComplete: () => {
              //     navigate(`/app/objectviewer`)
              //   },
              // })

              var tl = gsap.timeline({
                onComplete: () => {
                  navigate(`/app/profile/`)
                },
              })

              tl.to(swiper, {
                transform: `translateY(0vh)`,
                display: 'flex',
                duration: 0,
              }).to(swiper, {
                delay: '0.2',
                transform: 'translateY(-100vh)',
                duration: 0.5,
                display: 'flex',
              })
            } else {
              document.getElementById('rejection').style.display = 'block'
            }
          }}
        >
          <Name
            name="username"
            type="text"
            placeholder=""
            onChange={this.handleUpdate}
            required
          />
          <Submit name="submit" type="submit">
            <div className="textContainer">
              <p>Submit</p>
            </div>
            <div className="arrowContainer">
              <img src={arrow} alt="arrow" />
            </div>
          </Submit>

          <div className="rejection" id="rejection">
            <p>Invalid code.</p>
          </div>
        </Form>
      </LoginFormContainer>
    )
  }
}

export default Login
